import { NgModule } from '@angular/core';
import { APP_BASE_HREF, CommonModule, DatePipe } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { A11yModule } from '@angular/cdk/a11y';
import { MatSelectModule } from '@angular/material/select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxEchartsModule } from 'ngx-echarts';
import { ToastrModule } from 'ngx-toastr';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TokenInterceptor } from './token.interceptor';
import { CommonDirectivesModule } from './common/directives/commonDirectives.module';
import { MaterialModule } from './common/module/material.modules';
import { NgxDaterangepickerMd } from './common/components/daterangepicker/daterangepicker.module';
import { TranslocoRootModule } from './common/module/transloco.module';

@NgModule({
    declarations: [AppComponent],
    imports: [
        NgxEchartsModule.forRoot({
            /**
             * This will import all modules from echarts.
             * If you only need custom modules,
             * please refer to [Custom Build] section.
             */
            echarts: () => {return import('echarts');} // or import('./path-to-my-custom-echarts')
        }),
        ToastrModule.forRoot({
            timeOut: 2000,
            preventDuplicates: true,
            closeButton: true
        }),
        RouterModule,
        BrowserModule,
        A11yModule,
        MatSelectModule,
        CommonModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        CommonDirectivesModule,
        ScrollingModule,
        NgxDaterangepickerMd.forRoot(),
        TranslocoRootModule,
        AngularSvgIconModule.forRoot()
    ],
    bootstrap: [AppComponent],
    exports: [MaterialModule],
    providers: [
        DatePipe,
        { provide: APP_BASE_HREF, useValue: '/' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        }
    ]
})
export class AppModule {}