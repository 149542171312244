import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';

@Component({
    selector: 'liq-app',
    template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
    constructor(@Inject(DOCUMENT) private document: Document) {}

    ngOnInit(): void {
        // only show on dev and local for now
        if (location.hostname === 'dev.libraryiq.com' || location.hostname === 'localhost') {
            const script = this.document.createElement('script');
            script.innerHTML = `
                (function (w,d,s,o,f,js,fjs) {
                    w['JS-Widget']=o;w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) };
                    js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
                    js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
                }(window, document, 'script', 'mw', 'https://cdn.document360.io/static/js/widget.js'));
                mw('init', { apiKey: '${environment.doc360APIKey}' });
            `;
            this.document.body.appendChild(script);
        }
    }
}
